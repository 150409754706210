import actions from './propertyActions'

export const PROPERTY_TYPES = [
  // \u00A0 is for rendering whitespace in select options
  { value: 3, label: 'Woning' },
  { value: 8, label: '\u00A0\u00A0 Villa' },
  { value: 87, label: '\u00A0\u00A0\u00A0\u00A0 Bungalow' },
  { value: 35, label: '\u00A0\u00A0\u00A0\u00A0 Hoeve' },
  { value: 75, label: '\u00A0\u00A0\u00A0\u00A0 Landhuis' },
  { value: 41, label: '\u00A0\u00A0\u00A0\u00A0 Herenhuis' },
  { value: 73, label: '\u00A0\u00A0\u00A0\u00A0 Kasteel' },
  { value: 88, label: '\u00A0\u00A0 Huis met magazijn' },
  { value: 89, label: '\u00A0\u00A0 Woonboot' },
  { value: 90, label: '\u00A0\u00A0 Chalet' },
  { value: 97, label: '\u00A0\u00A0 Kangoeroewoning' },
  { value: 5, label: 'Appartement' },
  { value: 76, label: '\u00A0\u00A0 Dakappartement' },
  { value: 51, label: '\u00A0\u00A0\u00A0\u00A0 Loft' },
  { value: 47, label: '\u00A0\u00A0\u00A0\u00A0 Penthouse' },
  { value: 53, label: '\u00A0\u00A0 Gelijkvloers appartement' },
  { value: 6, label: '\u00A0\u00A0 Studio' },
  { value: 59, label: '\u00A0\u00A0 Studentenkamer' },
  { value: 91, label: '\u00A0\u00A0 Duplex' },
  { value: 92, label: '\u00A0\u00A0 Triplex' },
  { value: 93, label: '\u00A0\u00A0 Assistentiewoning' },
  { value: 11, label: 'Grond' },
  { value: 94, label: '\u00A0\u00A0 Bouwgrond' },
  { value: 95, label: '\u00A0\u00A0 Landbouwgrond' },
  { value: 12, label: 'Garage' },
  { value: 22, label: '\u00A0\u00A0 Staanplaats' },
  { value: 23, label: '\u00A0\u00A0 Garagebox' },
  { value: 71, label: 'Handel' },
  { value: 31, label: '\u00A0\u00A0 Handelspand' },
  { value: 25, label: '\u00A0\u00A0 Handelspand met woonst' },
  { value: 86, label: '\u00A0\u00A0 Handelspand met magazijn' },
  { value: 2, label: 'Horeca' },
  { value: 77, label: '\u00A0\u00A0 Eetgelegenheid' },
  { value: 79, label: '\u00A0\u00A0 Café' },
  { value: 64, label: '\u00A0\u00A0 Hotel / B&B' },
  { value: 83, label: '\u00A0\u00A0 Feestzaal' },
  { value: 74, label: 'Industrie' },
  { value: 80, label: '\u00A0\u00A0 Magazijn' },
  { value: 34, label: '\u00A0\u00A0\u00A0\u00A0 Magazijn met woonst' },
  { value: 26, label: '\u00A0\u00A0\u00A0\u00A0 Magazijn met kantoor' },
  { value: 82, label: '\u00A0\u00A0 Werkplaats' },
  { value: 28, label: '\u00A0\u00A0 Bedrijfsgebouw' },
  { value: 14, label: 'Kantoor' },
  { value: 49, label: '\u00A0\u00A0 Kantoor met woonst' },
  { value: 36, label: '\u00A0\u00A0 Kantoorgebouw' },
  { value: 81, label: 'Bedrijfsgrond' },
  { value: 63, label: '\u00A0\u00A0 Projectgrond' },
  { value: 33, label: '\u00A0\u00A0 Industriegrond' },
  { value: 85, label: 'Opbrengsteigendom' },
  { value: 96, label: 'Patrimoniumvennootschap' }
]

Object.freeze(PROPERTY_TYPES)

const schema = [
  {
    component: 'div',
    class: 'tw-my-4 tw-grid md:tw-grid-cols-2 xl:tw-grid-cols-4 tw-gap-4',
    children: [
      {
        type: 'select',
        name: 'type',
        label: 'Type',
        placeholder: 'Selecteer type',
        options: PROPERTY_TYPES,
        outerClass: 'tw-m-0'
      },
      {
        type: 'autocomplete',
        autoCompleteType: 'street',
        name: 'street',
        label: 'Straat',
        placeholder: 'Zoek een straat',
        outerClass: 'tw-m-0',
        '@callback': 'streetSelected'
      },
      {
        type: 'text',
        name: 'number',
        label: 'Nummer',
        placeholder: 'Nummer',
        outerClass: 'tw-m-0'
      },
      {
        type: 'text',
        name: 'box',
        label: 'Bus',
        placeholder: 'Bus',
        validation: 'max:10,length',
        outerClass: 'tw-m-0'
      },
      {
        type: 'autocomplete',
        autoCompleteType: 'city',
        name: 'city',
        label: 'Plaats',
        placeholder: 'Zoek op postcode of plaatsnaam',
        validation: 'required',
        showRequiredLabel: true,
        outerClass: 'tw-m-0'
      },
      {
        type: 'autocomplete',
        autoCompleteType: 'project',
        name: 'project',
        label: 'Project',
        placeholder: 'Zoek een project',
        outerClass: 'tw-m-0'
      },
      {
        type: 'text',
        name: 'unit_no',
        label: 'Entiteit nr',
        placeholder: 'Entiteit nr',
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'fase',
        label: 'Fase',
        placeholder: 'Selecteer fase',
        options: {
          0: 'Niet van toepassing',
          1: 'Fase 1',
          2: 'Fase 2',
          3: 'Fase 3',
          4: 'Fase 4'
        },
        outerClass: 'tw-m-0'
      },
      {
        type: 'select',
        name: 'publish_address',
        label: 'Adres zichtbaarheid',
        placeholder: 'Selecteer antwoord',
        options: {
          0: 'Publiceer adres niet',
          1: 'Publiceer adres zonder huisnummer',
          2: 'Publiceer adres'
        },
        validation: 'required',
        showRequiredLabel: true,
        outerClass: 'tw-m-0'
      },
      {
        id: 'availableFrom',
        type: 'date',
        name: 'available_from',
        label: 'Beschikbaar vanaf',
        placeholder: 'YYYY-MM-DD',
        validation: 'bail|optional|date:YYYY-MM-DD',
        outerClass: 'tw-m-0'
      },
      {
        type: 'checkbox',
        name: 'is_immediately_available',
        label: 'Onmiddellijk beschikbaar',
        outerClass: 'tw-my-auto',
        '@input': 'immediatelyAvailable'
      },
      {
        type: 'textarea',
        name: 'visit_arrangement',
        label: 'Bezoekregeling',
        placeholder: 'Bezoekregeling',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        type: 'textarea',
        name: 'key_arrangement',
        label: 'Sleutelregeling',
        placeholder: 'Sleutelregeling',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        type: 'textarea',
        name: 'notes',
        label: 'Notities',
        placeholder: 'Notities en opmerkingen',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        type: 'select',
        name: 'rental_commission_type',
        label: 'Commissieloon verhuur',
        placeholder: 'Selecteer huurcommissie',
        options: {
          0: 'VH 1 maand',
          1: 'VH beheer (0,5 maand)',
          2: 'VH 10% op jaarhuur',
          3: 'VH 15% op jaarhuur',
          4: 'VH 5% (personeelstarief)',
          5: 'VH 7,5% (personeelstarief)'
        },
        outerClass: 'tw-m-0'
      },
      {
        type: 'textarea',
        name: 'cost_price_inventory_of_fixtures',
        label: 'Kostprijs plaatsbeschrijving',
        placeholder: 'Kostprijs plaatsbeschrijving',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      },
      {
        type: 'checkbox',
        name: 'is_archived',
        label: 'Gearchiveerd',
        outerClass: 'md:tw-col-span-2 xl:tw-col-span-4'
      }
    ]
  }
]

export default {
  heading: 'Algemeen',
  schema,
  actions
}
